.App {
    text-align: center;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.footer {
  margin-top: auto;
  color: white;
}
/* Default size for larger screens */
.slick-prev,
.slick-next {
  font-size: 15px !important;
}

.slick-prev:before,
.slick-next:before {
  content: '' !important;
}

/* Smaller size for xs screens */
@media only screen and (max-width: 600px) {
  .slick-prev,
  .slick-next {
    font-size: 10px !important; /* Adjust the size as needed */
  }
}
.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

@media (max-width: 600px) {
  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-field {
  margin: 0.5rem;
  background-color: #f9f9f9;
}

.form-field {
  width: 17rem;
}

@media (max-width: 600px) {
  .form-field {
    width: 7rem;
  }
}

.form-field-wide {
  margin: 0.5rem;
  background-color: #f9f9f9;
}

.form-field-wide {
  width: 35rem;
}

@media (max-width: 600px) {
  .form-field-wide {
    width: 15rem;
  }
}



.form-field-bazar {
  margin: 0.5rem;
  width: 25rem;
  background-color: #f9f9f9;
}

.text-align-right .MuiMenuItem-root {
  justify-content: flex-start;
}

.Contact{
  background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
}

.ContactPerson{
  background-color: #f9f9f9;
    display: flex;
    flex-direction: row;
    padding-top: 2em;
    padding-bottom: 2em;
}

@media (max-width: 600px) {
  .ContactPerson{
    background-color: #f9f9f9;
      display: flex;
      flex-direction: row;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
  }
}

.Divider{
  border-color: #FF750E;
}

.footer-link {
  color: white;
  text-decoration: none;
  margin-top: 5px;
  transition: color 0.2s;
}

.footer-link:hover {
  color: #FF750E;
}

.image-container {
  height: 300px;
  width: 400px;
}

@media (max-width: 600px) {
  .image-container {
    height: 150px; /* 50% of the original height */
    width: 200px; /* 50% of the original width */
  }
}